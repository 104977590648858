import React, { Component } from "react";
import adbInstall from '../downloads/installAppV1_01.apk'
import adbDriver from '../downloads/driverAppV1_25.apk'
import presentationPdf from '../downloads/iBlastXwebpresentation.pdf'
import flyerPdf from '../downloads/iBlastXFlyer.pdf'
import installPdf from '../downloads/iBlastXInstall.pdf'
import overviewPdf from '../downloads/iBlastXOverView.pdf'
import ReactPlayer from 'react-player'
import { withTranslation } from 'react-i18next';
 
class Downloads extends Component {
  render() {
  const { t } = this.props;

    return (
      <div>

        <h3>{t('Presentation')}</h3>
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={presentationPdf}>{t('iBlastX Presentation')} 2021-07-13</a></li>
          </ul>
        </div>

        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={flyerPdf}>{t('iBlastX Flyer')} 2022-08-11</a></li>
          </ul>
        </div>

        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={overviewPdf}>{t('iBlastX Overview')} 2022-08-11</a></li>
          </ul>
        </div>

        <h3>{t('Videos')}</h3> 
        <div style={{ padding:'20px', clear:'both', width:'100%', margin: 'auto'}}>
          <ReactPlayer url='https://youtu.be/KcivUAwTq1Q' />
        </div>

        <div style={{ padding:'20px', clear:'both', width:'100%', margin: 'auto'}}>
          <ReactPlayer url='https://youtu.be/RpnQ-oIGCIc' />
        </div>

        <h3>{t('Documentation')}</h3> 
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={installPdf}>{t('Install_Manual')} 1.1 2021-04-09</a></li>                
          </ul> 
        </div>

         <h3>{t('Android_Applications')}</h3>
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={adbInstall}>{t('Android_Install_Application_Version')} 1.1 2019-03-25</a></li>
            <li><a href={'https://play.google.com/store/apps/details?id=com.iblastx.android.driverapp&gl=ZA'}>{t('Android_Driver_Application_Version')} 1.49 2022-04-23</a></li>
          </ul>
        </div>

      </div>   
    );
  }
}

export default withTranslation()(Downloads);
