import Button from '@mui/material/Button';
import React, { Component } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from './iblastxTagDark.png'
import Overview from '../images/iblastxOverview.png'
import Background from '../images/startBackground.jpg'
import Start0 from '../images/start0.png'
import Start1 from '../images/start1.png'
import Start2 from '../images/start2.png'
import Start3 from '../images/start3.png'
import Start4 from '../images/start4.png'
import YouTubeIblastX from '../images/youTubeIblastX.png'
import LinkedIn from '../images/linkedIn.png'

import ReactPlayer from 'react-player/youtube'


import { withTranslation } from 'react-i18next';
 
class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }

  onClickLogin = (event) => {
    console.log ("Start:onClickLogin",event);
    this.props.onClickLogin(event);
  }


  render() {
    const { t } = this.props;

    const rowCenter = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      color: 'white',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '100',
      fontSize: '30px',
      padding:'10px',
      textAlign: 'left',
    };

    return (
    <div 
      style={{
        backgroundColor:'#0E1213',
        background: {Background},
        backgroundSize: 'cover',
      }}>
      <div 
        style={{
          backgroundColor:'black',
          color: 'white',
          maxWidth: '1300px',
          margin: 'auto',
        }}>

        <Grid container >
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              sx={{
                height: 120,
                padding: '10px'
              }}
              alt="iBlastX"
              src={Logo}
            />
          </Grid>
    
          <Grid item xs={12} md={6} >
            <Box display="flex" justifyContent="flex-end" sx={{padding:'10px'}}>
              <Button 
                key={1} 
                variant="contained" 
                size="large" 
                style={{
                  borderRadius: 10,
                  backgroundColor: "#c00000",
                  fontSize: "12px",
                  height: "35px",
                  width: "200px"
                }}
                onClick={this.onClickLogin}> {t('Login')}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={12}
            sx={{ padding:'10px', paddingTop:'15px', fontWeight:'bold', fontSize:'40px',color: "white",textAlign: 'center',}}>
            <p>Better Blasting Through Digital Transformation</p>
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={12} md={6}
            order={{xs: 2, md: 1}}
            sx={{padding:'10px'}}>
            <div
              style={{
                fontSize: '30px',
                color: "#c00000",
              }}>
              <p>Do you know what is actually happening on your bench?</p>
            </div>

            <div
              style={{
                fontSize: '18px',
                paddingRight:'10px',
              }}>
              <ul>
                <li>Operators on the bench do not have easy access to information regarding the state of a hole or the quantity and type of explosives that have to be delivered to each hole on a blast pattern</li>
                <li>Holes are filed to stemming height, ignoring cracks, voids, long/short drilling, etc, and not the actual design weight</li>
                <li>There is minimal data available for analysis about what in reality happened on the bench, before or after blasting</li>
                <li>The data that exists is often incomplete and difficult to analyze</li>
              </ul>
            </div>
          </Grid>

          <Grid item xs={12} md={6} 
            order={{xs: 1, md: 2}}
            sx={{padding:'10px'}}>
            <Box display="flex" justifyContent="center"
               component="img"
               sx={{
                 border: 1,
                 borderColor: 'white',
                 borderRadius: '10px',
                 width: '100%',
               }}
               alt="iBlastX"
               src={Start0}
            />
          </Grid>
        </Grid>


        <Grid container >
          <Grid item xs={12} md={12} 
            sx={{padding:'10px'}}>
            <div style={{ padding:'20px', clear:'both', width:'100%', margin: 'auto'}}>
              <ReactPlayer
                url='https://youtu.be/KcivUAwTq1Q'
                playing={true}
                width="100%"
                height="500px"
                muted={true}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}  
            order={{xs: 1, md: 1}}
            sx={{padding:'10px'}}>
            <div
              style={{
                display: 'inline',
                fontSize: '30px',
                color: "#c00000",
              }}>
              <p>How</p>
            </div>

            <div
              style={{
                fontSize: '18px',
                paddingRight:'10px',
              }}>
              <ul>
                <li>Easy and quick to install hardware that works on almost any MPU. Makes even your dumb truck smarter</li>
                <li>Ruggedized, off the shelf, tablets running our custom, easy to use applications for charging, surveying and priming</li>
                <li>An intuitive easy to use web application allows you to securely access and analyze your data anytime, anywhere</li>
                <li>Differential GPS for cm/inch accuarte surveyed hole positions</li>
                <li>Real-time email notifications</li>
                <li>Multiple language support on both tablet and web applications for easier end-user acceptance</li>
                <li>REST APIs</li>
                <li>Local in country agents for support</li>
                <li>Active and responsive product development</li>
              </ul>
            </div>
          </Grid>

          <Grid item xs={12} md={6}
            order={{xs: 1, md: 2}}
            sx={{padding:'10px'}}>
            <div
              style={{
                display: 'inline',
                fontSize: '30px',
                color: "#c00000",
              }}>
              <p>What we do</p>
            </div>

            <div
              style={{
                fontSize: '18px',
                paddingRight:'10px',
              }}>
              <ul>
                <li>Electronically record actual real-time QAQS (as drilled hole/water depth, differential GPS position, and hole images), priming (boosters/detonators installed), and charge (explosives loaded) data on the bench as it happens</li>
                <li>Provide operators on the bench with accurate per-hole charge information</li>
                <li>Real-time web-based reporting and monitoring of what is happening on the bench, from anywhere in the world</li>
                <li>Exceptions management (driver speeding, overloading, hole conditions, etc.)</li>
                <li>Real-time exception notifications</li>
                <li>Data insights and visualizations</li>
                <li>Data exports to 3rd party systems</li>
              </ul>
            </div>
          </Grid>

        </Grid>

        <Grid container >
          <Grid item xs={12} md={6} 
            order={{xs: 2, md:1 }}
            sx={{padding:'10px'}}>
            <Box
               component="img"
               sx={{
                 border: 1,
                 borderColor: '#c00000',
                 borderRadius: '10px',
                 width: '100%',
                 minWidth: '300px',
                 height: 'auto',
               }}
               alt="iBlastX"
               src={Start4}
            />
          </Grid>

          <Grid item xs={12} md={6} 
            order={{xs: 1, md: 2}}
            sx={{padding:'10px'}}>
            <div
              style={{
                display: 'inline',
                fontSize: '30px',
                color: "#c00000",
              }}>
              <p>Value</p>
            </div>

            <div
              style={{
                fontSize: '18px',
                paddingRight:'10px',
              }}>
              <ul>
                <li>Accurate blast information prior to blast</li>
                <li>Data driven decision making</li>
                <li>Continuous improvement</li>
                <li>Manage exceptions as they happen</li>
                <li>Ability to track and monitor over/under loading of holes</li>
                <li>Determine the actual cost of a blast</li>
                <li>Post blast investigation tool</li>
                <li>Safety review for overloaded holes prior to blasting</li>
                <li>Internal audit tool for invoicing and reconciliation</li>
                <li>Explosive, booster and detonator reconciliation</li>
                <li>Reduce explosives cost and CO<sup>2</sup> emissions</li> 
                <li>Manage truck utilization</li>
                <li>Automated blast reports</li>
              </ul>
            </div>
          </Grid>

        </Grid>

        <Grid container >
          <Grid item xs={12} 
            sx={{ padding:'10px', paddingTop:'15px', fontWeight:'bold', fontSize:'30px',color: "#c00000",textAlign: 'center',}}>
            <span>Enhance safety, reduce costs and CO<sup>2</sup> emissions with improved charge management</span>
          </Grid>
 
        </Grid>

        <Grid container >
          <Grid item xs={12} md={4}
            order={{xs: 1, md: 1}}
            sx={{ padding:'10px', paddingTop:'15px', fontWeight:'bold', fontSize:'30px',color: "#c00000",textAlign: 'center',}}>
              <a href="https://www.youtube.com/@iblastx" target="_blank" rel="noreferrer">
                <img src={YouTubeIblastX} alt="iBlastX YouTube Channel" style={{ width: 200}}></img>
              </a>
          </Grid>

          <Grid item xs={12} md={4}
            order={{xs: 2, md: 3}}
            sx={{ padding:'10px', paddingTop:'15px', fontWeight:'bold', fontSize:'30px',color: "#c00000",textAlign: 'center',}}>
              <a href="https://www.linkedin.com/company/iblastx/" target="_blank" rel="noreferrer">
                <img src={LinkedIn} alt="iBlastX LinkedIn" style={{ width: 200}}></img>
              </a>
          </Grid>

          <Grid item xs={12} md={4}
            order={{xs: 3, md: 2}}
            sx={{ padding:'10px', paddingTop:'15px', fontWeight:'bold', fontSize:'30px',color: "#c00000",textAlign: 'center',}}>
            <div style={{backgroundColor:'black', padding:'0px'}}>
              <div style={{...rowCenter, padding:'0px', fontSize:'14px'}}>
                <span>Contact Us</span>
              </div>
             <div style={{...rowCenter, padding:'0px', fontSize:'14px'}}>
               <span style={{padding:0,fontSize:'14px'}}>gideonkrige@iblastx.com</span>
             </div>
             <div style={{...rowCenter, padding:'0px', fontSize:'14px'}}>
               <span style={{padding:0,fontSize:'14px'}}>or your local agent</span>
             </div>
           </div>
         </Grid>
       </Grid>

      </div>
    </div>
    );
  }
}

export default withTranslation()(Start);
