import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import DesignData from './DesignData.js';
import LoadTable from './LoadTable';
import LoadAdd from './LoadAdd';
import DesignDeckTable from './DesignDeckTable';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';


class MapDesignInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    loads       : [],
    products    : [],
    stemming    : [],
    };
  }

  componentDidMount() {
    console.log("MapDesignInfoWindow:componentDidMount");
    this.getLoads();
   // this.getProducts();
  //  this.getStemming();
  }

  refreshLoadData = () => {
    console.log("MapDesignInfoWindow:refreshLoadData");
    this.getLoads();
    this.props.refreshData();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  refreshDesignData = () => {
    console.log("MapDesignInfoWindow:refreshDesignData");
    this.props.refreshData();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }


  getProducts = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        typeId    : 1,
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ products:data }))
    .catch(error => this.setState({ products:[] }));
  }

  getStemming = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        typeId    : 0,
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ stemming:data }))
    .catch(error => this.setState({ stemming:[] }));
  }

  getLoads = () => {
    console.log("MapDesignInfoWindow:getLoads",this.props.designInfo.siteId,this.props.designInfo.patternNo,this.props.designInfo.holeNo);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.designInfo.siteId,
         patternNo : this.props.designInfo.patternNo,
         holeNo    : this.props.designInfo.holeNo,
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({loads:data}) )
    .catch(error => this.setState({loads:[]}));
  }

  handleClick = (event) => {
    console.log("MapDesignInfoWindow:handleClick:");
    event.stopPropagation(); //Otherwise HeatMap toggle
  }

  handleCancel = (event) => {
    console.log("MapDesignInfoWindow:handleCancel");
    this.props.handleMarkerClear();
  }

  render() {
    const { t } = this.props;

    console.log ("MapDesignInfoWindow:render",this.props.designInfo, this.props.markerClickInfo);

    const infoWindowStyle = {
      position: 'relative',
      bottom: 300,
      left: '-100px',
      width: ((this.props.markerClickInfo !== null) && (this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.props.loadAddEnabled))?1050:850,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.9
    };

    return (
      <div key='MapDesignInfoWindow' onClick={this.handleClick} style={infoWindowStyle}>

        <div style={{ color:'white', fontSize: 20, backgroundColor: 'Black' }}>
          <span style={{textAlign:'left', fontWeight:'bold' }}> {this.props.designInfo.patternNo} </span>
          <span style={{float:'right', textAlign:'right', fontWeight:'bold'}}> {this.props.designInfo.holeNo} </span>
        </div>

        <hr style={{margin:2}}></hr>

        <DesignData
          userDetails = {this.props.userDetails}
          designData  = {this.props.designInfo}
          products    = {this.props.products}
          stemming    = {this.props.stemming}
        /> 
        
      {(this.state.products)!=null && (this.state.stemming!=null) && [
          <DesignDeckTable
            key         = {10}
            userDetails = {this.props.userDetails}
            refreshData = {this.refreshDesignData}
            designData  = {this.props.designInfo}
            products    = {this.state.products}
            stemming    = {this.state.stemming}
          />
        ]}

        {(this.state.loads.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Loads</h6>
            <LoadTable
              userDetails       = {this.props.userDetails}
              refreshData       = {this.refreshLoadData}
              loads             = {this.state.loads}
              siteId            = {this.props.designInfo.siteId}
              handleMarkerClear = {this.props.handleMarkerClear}
            />
          </div>
        ]}

        {(this.props.markerClickInfo !== null) &&[
         <div
           style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
           }}
         >

           {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.props.loadAddEnabled))?
             <div>
               <LoadAdd
                 userDetails  = {this.props.userDetails}
                 siteId       = {this.props.designInfo.siteId}
                 patternNo    = {this.props.designInfo.patternNo}
                 holeNo       = {this.props.designInfo.holeNo}
                 latitude     = {this.props.designInfo.latitude}
                 longitude    = {this.props.designInfo.longitude}
                 handleAdd    = {this.refreshLoadData}
                 handleCancel = {null}
               />
             </div>:null
           }

           <Button variant="contained" size="small" onClick={this.handleCancel}>{t('Cancel')}</Button>
         </div>
       ]}

      </div>
    );
  }
}

export default withTranslation()(MapDesignInfoWindow);
