import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MapDesignInfoWindow from './MapDesignInfoWindow';

class MapDesignMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

  }

  handleClick = (event) => {
    console.log("MapDesignMarker:handleClick:", this.props.designInfo);
    this.props.handleMarkerClick(this.props.designInfo);
  }

  render() {
   let border = '2px solid red' ;
    let radius=25;

   const markerStyle = {
      border: (this.props.$hover||this.props.selected || this.props.proximity)?'2px solid fuchsia':border,
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius,
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      fontWeight: (this.props.$hover||this.props.markerClickId===this.props.designInfo.holeId) ? 'bold':'normal',
      backgroundColor: (this.props.selected || this.props.$hover || this.props.showInfoWindow || this.props.proximity)? 'fuchsia' : 'white',
      cursor: 'pointer',
      position: 'absolute',
      optimized: true,
      textAlign: 'center',
      lineHeight: '22px',
      zIndex: 5 
    };

    const textStyle = {
      color: 'black',
      fontSize: this.props.$hover ? 12:11,
      fontWeight: this.props.$hover ? 'bold':'normal',
    };

    return (
      <div>
       <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.designInfo.holeNo}</span> </div>
        {((this.props.$hover && this.props.markerClickInfo===null && this.props.loadClickInfo===null) ||  (this.props.showInfoWindow)) && //hover but not clicked OR Clicked
          <MapDesignInfoWindow
            isInfoWindow={true}
            designInfo={this.props.designInfo}
            products={this.props.products}
            stemming={this.props.stemming}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            markerClickInfo   = {this.props.markerClickInfo}
            loadClickInfo     = {null}
            loadAddEnabled    = {this.props.loadAddEnabled}
            userDetails={this.props.userDetails} />}
      </div>
    );
  }
}

export default MapDesignMarker;

