import React from 'react';
import ReactDOM from 'react-dom';
import i18n from '../translations/i18n';
 
const MapSurveyLegend=(props)=>
{
  //const t = i18n.t;
  const t = i18n.t.bind(i18n);

  return(
    <div  style={{paddingTop: '3px', height:'30px',backgroundColor: 'white'}}>
    <div style={{ float:'left',width: "20px", height: "20px", background: "lightgrey", borderRadius: "50%", border: "3px solid lightgrey"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem',color:'black'}}> {t('Survey_None')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "aqua", borderRadius: "50%", border: "3px solid aqua"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Depth_OK')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "aqua", borderRadius: "50%", border: "3px solid crimson"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Depth_Over')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "aqua", borderRadius: "50%", border: "4px solid blue"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Depth_Under')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "blue", borderRadius: "50%", border: "3px solid blue"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Loaded')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "gold", borderRadius: "50%", border: "3px solid gold"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Stemming_OK')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "gold", borderRadius: "50%", border: "3px solid crimson"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Stemming_Over')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "gold", borderRadius: "50%", border: "4px solid blue"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Survey_Stemming_Under')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "black", borderRadius: "50%", border: "4px solid fuchsia"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Collapsed')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "black", borderRadius: "50%", border: "4px solid crimson"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('OverBurden')} </div>

    <div style={{ float:'left',width: "20px", height: "20px", background: "black", borderRadius: "50%", border: "4px solid blue"}}></div>
    <div style={{ float:'left', marginRight: '1.0rem', color:'black'}}> {t('Cracked')} </div>
  </div>
  )
}
 
export default MapSurveyLegend;
